$(document).ready(function() {

    //$('.flex-container ul').wrap('<div></div>');

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('input[type="date"], .date-input').datetimepicker({
        locale: 'it',
        format: 'DD/MM/YYYY'
    });

    $('.date-input-format').datetimepicker({
        locale: 'it',
        format: 'MM/YYYY'
    });

    $('.selectpicker').selectpicker();

    $('#decrease-chars').click(function(event) {
        event.preventDefault();
        if(!$(this).hasClass('active-modify')) {
            $('*').not('.no-modify-chars').each(function (index, value) {
                modifyChars(value, 90);
            });
            $('#increase-chars').addClass('active-modify').css('display', 'none').off('click');
            $('#decrease-chars').removeClass('active-modify').css('color', 'darkgrey').css('font-weight', 'bold').off('click');
            $('#normal-chars').removeClass('active-modify');
        }
    });
    
    $('#increase-chars').click(function(event) {
        event.preventDefault();
        if(!$(this).hasClass('active-modify')) {
            $('*').not('.no-modify-chars').each(function (index, value) {
                modifyChars(value, 130);
            });
            $('#increase-chars').addClass('active-modify').css('color', 'darkgrey').css('font-weight', 'bold').off('click');
            $('#decrease-chars').removeClass('active-modify').css('display', 'none').off('click');
            $('#normal-chars').removeClass('active-modify');
        }
    });
    
    $('#normal-chars').click(function() {
        window.location.reload();
    });
});

function modifyChars(value, perc) {
    var k = parseInt($(value).css('font-size'));
    var size = ((k * perc) / 100);
    var dim = $(value).css('font-size').replace(/[0-9]/g, '');
    $(value).css('font-size', size + dim);
}
