
    function checkResult() {
        var form = document.getElementById("serviziocs");
        var div_errore = document.getElementById("error-model");
        var text_modale = document.getElementById('text_modale_cs')
        var risultati_negativi = 0;
        var risposte = [];
        var i;
        var n = 0;
        var recaptcha = document.forms["serviziocs"]["g-recaptcha-response"].value;
        if (recaptcha == "") {
            alert("Completare prima il processo di ReCaptcha.");
            return false;
        }

        //metto in un array i valori del form
        for (i = 0; i < form.length; i++) {
            if (form.elements[i].checked && (form.elements[i].className != 'bool-cs')) {
                risposte[n] = form.elements[i].value;
                n++;
            }
        }

        //Conto i risultati con valore minore di 3
        for (var x = 0; x < risposte.length; ++x) {
            if (risposte[x] < 3){
                risultati_negativi++;
            }
        }

        //controllo se ci sono risultati negativi o se il testo del modale ha almeno 150 caratteri
        if ((risultati_negativi  >= 1) && (text_modale.value.length < 150)) {
            text_modale.required = true;
            if ((text_modale.value.length >= 1) && (text_modale.value.length < 150)) {
                div_errore.innerHTML = 'Non è stato raggiunto il numero minimo di caratteri consentiti.';
            }
            $('#modale_cs').modal('show');
            return false;
        }
        else {
            text_modale.required = false;
            return true;
        }
    }